var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page_title_contain"},[_c('header',{staticClass:"index_head"},[_c('div',{staticClass:"common_warp header_wrap"},[_c('div',{staticClass:"icon",on:{"click":() => {
              _vm.getIndex()
            }}},[_c('img',{attrs:{"src":require("../assets/white_logo.png"),"alt":""}})]),_c('div',{staticClass:"menu index_page_menu"},[_c('p',{staticClass:"menu_item",on:{"click":() => {
                _vm.$router.push('/more')
              }}},[_vm._v(" 全部活动 ")]),_c('p',{staticClass:"menu_item",on:{"click":() => {
                _vm.$router.push('/about')
              }}},[_vm._v(" 关于我们 ")]),_c('a-input-search',{staticClass:"input_search",attrs:{"placeholder":"夏校、机器人、耶鲁、纽约…","enter-button":"搜索","size":"large","maxLength":100,"list":"data-list"},on:{"search":_vm.onSearch},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}},[_c('a-icon',{style:({ color: '#cdcdcd' }),attrs:{"slot":"prefix","type":"search"},slot:"prefix"})],1),_c('datalist',{attrs:{"id":"data-list"}},_vm._l((_vm.history),function(item,i){return _c('option',{key:i,domProps:{"value":item}})}),0)],1),_c('div',{staticClass:"language index_page_language"},[_c('span',{staticClass:"pointer"},[_vm._v("中文")]),_vm._v(" "),_c('span',{staticClass:"line"},[_vm._v("|")]),_c('span',{staticClass:"pointer",on:{"click":() => {
                _vm.$router.push('english')
              }}},[_vm._v("English")])])]),_vm._m(0),_c('div',{staticClass:"slogan_wrap"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.videoDone),expression:"!videoDone"}],attrs:{"src":require("../assets/index_slogan_bg.png"),"alt":""}}),_c('div',{staticClass:"slogan_mask"}),_c('video',{directives:[{name:"show",rawName:"v-show",value:(_vm.videoDone),expression:"videoDone"}],ref:"sloganVideo",attrs:{"autoplay":"","muted":"","loop":"","id":"slogan","poster":require("../assets/index_slogan_bg.png")},domProps:{"muted":true}},[_c('source',{attrs:{"src":require("../assets/sixmb.mp4"),"type":"video/mp4"}})])])])]),_c('div',{staticClass:"home_common_warp"},[_vm._m(1),_c('div',{staticClass:"icon_menu"},[_c('ul',{class:['accsee', _vm.enterStyle]},_vm._l((_vm.accessList),function(item,index){return _c('li',{key:'assess' + index,staticClass:"accseeItem",attrs:{"data-name":item.name},on:{"click":() => {
              _vm.$router.push({
                query: {
                  entryType: item.entryType,
                  objectId: item.id,
                  name: item.name,
                  access: true,
                  typeId: item.typeId,
                },
                path: 'more',
              })
            }}},[(_vm.enterStyle == 'FOUR_PLUS_TWO')?[_c('img',{attrs:{"src":require(`@/assets/king/pic${index}.png`),"alt":""}})]:[_c('img',{attrs:{"src":item.icon.url,"alt":""}})],_c('div',{staticClass:"accseeItem_name"},[_vm._v(_vm._s(item.name))])],2)}),0)]),_c('ul',{staticClass:"floor_wrap"},_vm._l((_vm.floorList),function(item){return _c('li',{key:item.id},[_c('div',{staticClass:"floor_title"},[_c('div',{staticClass:"floor_title_name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"floor_title_more",on:{"click":function($event){return _vm.toMore(item)}}},[_c('span',[_vm._v("查看更多")]),_c('img',{attrs:{"src":require("../assets/more_icon.png"),"alt":""}})])]),_c('article-list',{attrs:{"flex":true,"list":item.active,"styleName":'indexStyle'}})],1)}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slogan_image"},[_c('img',{attrs:{"src":require("../assets/slogan.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page_title"},[_c('div',{staticClass:"title_text"},[_vm._v("Our Promise")]),_c('div',{staticClass:"title_info"},[_vm._v(" 我们将始终保持中立，提供活动信息和客观评价，为真正优秀的课外活动提供展示的平台。 ")])])
}]

export { render, staticRenderFns }