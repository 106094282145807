<template>
  <div>
    <div class="page_title_contain">
      <header class="index_head">
        <div class="common_warp header_wrap">
          <div
            class="icon"
            @click="
              () => {
                getIndex()
              }
            "
          >
            <img src="../assets/white_logo.png" alt="" />
          </div>
          <div class="menu index_page_menu">
            <p
              class="menu_item"
              @click="
                () => {
                  $router.push('/more')
                }
              "
            >
              全部活动
            </p>
            <p
              class="menu_item"
              @click="
                () => {
                  $router.push('/about')
                }
              "
            >
              关于我们
            </p>
            <a-input-search
              class="input_search"
              placeholder="夏校、机器人、耶鲁、纽约…"
              enter-button="搜索"
              size="large"
              v-model="keyword"
              :maxLength="100"
              @search="onSearch"
              list="data-list"
            >
              <a-icon
                slot="prefix"
                type="search"
                :style="{ color: '#cdcdcd' }"
              />
            </a-input-search>
            <datalist id="data-list">
              <option
                v-for="(item, i) in history"
                :key="i"
                :value="item"
              ></option>
            </datalist>
          </div>
          <div class="language index_page_language">
            <span class="pointer">中文</span> <span class="line">|</span
            ><span
              class="pointer"
              @click="
                () => {
                  $router.push('english')
                }
              "
              >English</span
            >
          </div>
        </div>
        <div class="slogan_image">
          <img src="../assets/slogan.png" alt="" />
        </div>
        <div class="slogan_wrap">
          <img src="../assets/index_slogan_bg.png" v-show="!videoDone" alt="" />
          <div class="slogan_mask"></div>

          <video
            v-show="videoDone"
            ref="sloganVideo"
            autoplay
            muted
            loop
            id="slogan"
            poster="../assets/index_slogan_bg.png"
          >
            <source src="../assets/sixmb.mp4" type="video/mp4" />
          </video>
        </div>
      </header>
    </div>
    <div class="home_common_warp">
      <div class="page_title">
        <div class="title_text">Our Promise</div>
        <div class="title_info">
          我们将始终保持中立，提供活动信息和客观评价，为真正优秀的课外活动提供展示的平台。
        </div>
      </div>
      <div class="icon_menu">
        <ul :class="['accsee', enterStyle]">
          <li
            class="accseeItem"
            v-for="(item, index) in accessList"
            :key="'assess' + index"
            :data-name="item.name"
            @click="
              () => {
                $router.push({
                  query: {
                    entryType: item.entryType,
                    objectId: item.id,
                    name: item.name,
                    access: true,
                    typeId: item.typeId,
                  },
                  path: 'more',
                })
              }
            "
          >
            <template v-if="enterStyle == 'FOUR_PLUS_TWO'">
              <img :src="require(`@/assets/king/pic${index}.png`)" alt="" />
            </template>
            <template v-else>
              <img :src="item.icon.url" alt="" />
            </template>
            <div class="accseeItem_name">{{ item.name }}</div>
          </li>
        </ul>
      </div>
      <ul class="floor_wrap">
        <li v-for="item in floorList" :key="item.id">
          <div class="floor_title">
            <div class="floor_title_name">{{ item.name }}</div>
            <div class="floor_title_more" @click="toMore(item)">
              <span>查看更多</span>
              <img src="../assets/more_icon.png" alt="" />
            </div>
          </div>
          <article-list
            :flex="true"
            :list="item.active"
            :styleName="'indexStyle'"
          />
        </li>
      </ul>
      <!--  <div class="parters_list">
        <div class="parters_list_title">课外大师的小伙伴们</div>
        <div class="parters_item">
          <img
            v-for="item in partners"
            :src="require(`@/assets/parters/${item}`)"
            alt=""
            :key="`${item}`"
          />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getCategory, getFloor, search } from '@/api/home'
import articleList from '@/components/articleList'
import storage from '@/utils/storage'
import util from '@/utils/utils.js'
export default {
  name: 'home',
  components: { articleList },
  data() {
    return {
      accessList: [],
      keyword: '',
      floorList: [],
      partners: [
        'pic1.png',
        'pic2.png',
        'pic3.png',
        'pic4.png',
        'pic5.png',
        'pic6.png',
        'pic7.png',
        'pic8.png',
        'pic9.png',
      ],
      enterStyle: 'FOUR_PLUS_TWO',
      videoDone: false,
      history: [],
    }
  },
  created() {
    this.getIndex()
    this.history = storage.get('history') || []
  },
  mounted() {
    this.$refs.sloganVideo.oncanplay = () => {
      this.videoDone = true
    }
  },
  methods: {
    async getIndex() {
      const [
        {
          value: {
            data: { entry, code },
          },
        },
        {
          value: { data },
        },
      ] = await Promise.allSettled([getCategory(), getFloor()])
      this.accessList = entry
      this.enterStyle = code
      this.floorList = data
    },
    onSearch() {
      if (!this.keyword) {
        return
      }
      if (this.keyword && !this.history.includes(this.keyword)) {
        if (this.history.length >= 10) {
          this.history.splice(this.history.length - 1, 1)
        }
        this.history.unshift(this.keyword)
        storage.set('history', this.history)
      }
      this.$router.push({
        params: {
          keyword: this.keyword,
        },
        name: 'more',
      })
    },
    toMore(item) {
      this.$router.push({
        query: {
          name: item.name,
          objectId: item.id,
          typeId: item.typeId,
        },
        path: 'more',
      })
    },
  },
}
</script>
<style lang="less" scoped>
.page_title_contain {
  .common_warp,
  .slogan_image {
    position: relative;
    z-index: 1;
  }

  header .slogan_wrap {
    position: absolute;
    z-index: 0;
    .slogan_mask {
      position: absolute;
      z-index: 20;
      width: 100%;
      height: 450px;
      max-width: 1920px;
      background: #000000;
      opacity: 0.2;
    }
  }
}

.home_common_warp {
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 1280px;
}

.page_title {
  text-align: center;
  margin: 50px 0;

  .title_text {
    height: 44px;
    font-size: 32px;
    font-family: AlibabaPuHuiTiM;
    color: #333333;
    line-height: 44px;
  }

  .title_info {
    margin-top: 8px;
    height: 28px;
    font-size: 18px;
    font-family: AlibabaPuHuiTiR;
    color: #333333;
    line-height: 28px;
  }
}

.icon_menu {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.accsee {
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  align-content: space-between;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 100px;

  .accseeItem {
    text-align: center;
    margin-top: 60px;
    cursor: pointer;

    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;
    }

    .accseeItem_name {
      height: 33px;
      font-size: 24px;
      font-family: AlibabaPuHuiTiM;
      color: #333333;
      line-height: 33px;
      margin-top: 16px;
    }
  }
}

.accsee.FOUR_PLUS_TWO {
  .accseeItem {
    margin-left: 200px;
  }

  .accseeItem:nth-child(4n + 1) {
    margin-left: 0;
  }
}

.accsee.FIVE_PLUS_TWO {
  .accseeItem {
    margin-left: 112px;
  }

  .accseeItem:nth-child(5n + 1) {
    margin-left: 0;
  }
}

.floor_wrap {
  width: 1200px;
  margin-top: 80px;
  margin-bottom: 100px;

  .floor_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;

    .floor_title_name {
      height: 44px;
      font-size: 32px;
      font-family: AlibabaPuHuiTiM;
      color: #333333;
      line-height: 44px;
    }

    .floor_title_more {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      span {
        height: 26px;
        font-size: 18px;
        font-family: AlibabaPuHuiTiR;
        color: #666666;
        line-height: 25px;
      }

      img {
        height: 20px;
        width: 20px;
        margin-left: 8px;
      }
    }
  }
}

.parters_list {
  width: 100%;
  background-color: #fff;
  padding-top: 100px;
  padding-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

  .parters_list_title {
    height: 44px;
    font-size: 32px;
    font-family: AlibabaPuHuiTiM;
    color: #333333;
    line-height: 44px;
    margin-bottom: 40px;
  }

  .parters_item {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    width: 1280px;

    img {
      height: 78px;
      width: 210px;
      margin-bottom: 60px;
    }

    img + img {
      margin-left: 38px;
    }
  }
}
</style>
